<template>
  <form>
    <md-card>
      <md-card-header
        class="md-card-header-icon"
        :class="getClass(headerColor)"
      >
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
        <h4 class="title">
          {{ $t(`users.${oper}`) }}
          <small></small>
        </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('email') && touched.email},
                  {'md-error': errors.has('email')}
                ]"
              >
                <label>{{ $t('users.email') }}</label>
                <md-input
                  v-model="email"
                  type="email"
                  data-vv-name="email"
                  required
                  v-validate="modelValidations.email"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('email')">
                    close
                  </md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('email') && touched.email"
                  >
                    done
                  </md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('firstname') && touched.firstname},
                  {'md-error': errors.has('firstname')}
                ]"
              >
                <label>{{ $t('users.firstname') }}</label>
                <md-input
                  v-model="firstname"
                  type="text"
                  data-vv-name="firstname"
                  required
                  v-validate="modelValidations.firstname"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('firstname')">
                    close
                  </md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('firstname') && touched.firstname"
                  >
                    done
                  </md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('lastname') && touched.lastname},
                  {'md-error': errors.has('lastname')}
                ]"
              >
                <label>{{ $t('users.lastname') }}</label>
                <md-input
                  v-model="lastname"
                  type="text"
                  data-vv-name="lastname"
                  required
                  v-validate="modelValidations.lastname"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('lastname')">
                    close
                  </md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon
                    class="success"
                    v-show="!errors.has('lastname') && touched.lastname"
                  >
                    done
                  </md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('usergroup') && touched.usergroup},
                  {'md-error': errors.has('usergroup')}
                ]"
              >
                <label>{{ $t('users.group') }}</label>
                <md-select
                  v-model="usergroup"
                  name="usergroup"
                  id="usergroup"
                  data-vv-name="usergroup"
                  required
                  v-validate="modelValidations.usergroup"
                >
                  <md-option
                    v-for="group in groups"
                    :key="group.id"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-radio
                v-for="role in roles"
                :key="role"
                v-model="userrole"
                :value="role"
              >
                {{ $t(`users.role_${role}`) }}
              </md-radio>
            </div>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button
              class="md-success"
              native-type="submit"
              @click.native.prevent="validate"
            >
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onCancel">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import {mapState} from 'vuex'
import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'edit-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  components: {
    SlideYDownTransition
  },
  created() {
    const {uid = null} = this.$route.params
    this.uid = uid
    if (this.oper === 'upd' && uid) {
      this.$store.dispatch('LOAD_USER_BY_ID', uid).then((res) => {
        const user = Array.isArray(res) ? res[0] : res
        this.email = user.email
        this.lastname = user.last_name
        this.firstname = user.first_name
        this.usergroup = user.group_id
        this.userrole = user.role || 'user'
      })
    }
    this.$store.dispatch('LOAD_USERS_GROUPS')
  },
  data() {
    return {
      roles: ['admin', 'user'],
      uid: null,
      email: null,
      lastname: null,
      firstname: null,
      usergroup: null,
      userrole: 'user',
      touched: {
        email: false,
        firstname: false,
        lastname: false,
        usergroup: false
      },
      modelValidations: {
        email: {
          required: true,
          email: true
        },
        firstname: {
          required: true,
          min: 5
        },
        lastname: {
          required: true,
          min: 5
        },
        usergroup: {
          required: true
        }
      }
    }
  },
  methods: {
    onCancel() {
      this.$router.push('/users')
    },
    getClass: function(headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const user = {
            email: this.email,
            first_name: this.firstname,
            last_name: this.lastname,
            group_id: this.usergroup,
            role: this.userrole
          }

          const alert = {
            type: 'success',
            text: this.$t(`users.user_was_${this.oper}ed`, {
              name: this.firstname
            }),
            footer: ''
          }

          const reqData = this.oper === 'add' ? user : {id: this.uid, user}
          this.$store.dispatch(`${this.oper.toUpperCase()}_USER`, reqData).then(
            () => {
              Swal.fire(alert).then(() => {
                this.touched.email = false
                this.touched.firstname = false
                this.touched.lastname = false
                this.touched.usergroup = false

                this.$nextTick(() => this.$validator.reset())
              })
            },
            (err) => {
              console.log({err})
              alert.type = 'error'
              alert.text = this.$t(`users.user_was_not_${this.oper}ed`, {
                name: this.firstname
              })
              alert.footer = err
              Swal.fire(alert)
            }
          )
        }
      })
    }
  },
  watch: {
    email() {
      this.touched.email = true
    },
    firstname() {
      this.touched.firstname = true
    },
    lastname() {
      this.touched.lastname = true
    },
    usergroup() {
      this.touched.usergroup = true
    }
  },
  computed: {
    ...mapState({
      groups: (state) => state.Users.groups
    })
  }
}
</script>
<style>
.md-button + .md-button {
  margin-left: 10px;
}
</style>
