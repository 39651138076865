var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon",class:_vm.getClass(_vm.headerColor)},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("perm_identity")])],1),_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(("users." + _vm.oper)))+" "),_c('small')])]),_c('md-card-content',[_c('div',{staticClass:"md-layout md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('email') && _vm.touched.email},
                {'md-error': _vm.errors.has('email')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('users.email')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.email),expression:"modelValidations.email"}],attrs:{"type":"email","data-vv-name":"email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('email')),expression:"errors.has('email')"}],staticClass:"error"},[_vm._v(" close ")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('email') && _vm.touched.email),expression:"!errors.has('email') && touched.email"}],staticClass:"success"},[_vm._v(" done ")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('firstname') && _vm.touched.firstname},
                {'md-error': _vm.errors.has('firstname')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('users.firstname')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.firstname),expression:"modelValidations.firstname"}],attrs:{"type":"text","data-vv-name":"firstname","required":""},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('firstname')),expression:"errors.has('firstname')"}],staticClass:"error"},[_vm._v(" close ")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('firstname') && _vm.touched.firstname),expression:"!errors.has('firstname') && touched.firstname"}],staticClass:"success"},[_vm._v(" done ")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('lastname') && _vm.touched.lastname},
                {'md-error': _vm.errors.has('lastname')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('users.lastname')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.lastname),expression:"modelValidations.lastname"}],attrs:{"type":"text","data-vv-name":"lastname","required":""},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('lastname')),expression:"errors.has('lastname')"}],staticClass:"error"},[_vm._v(" close ")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('lastname') && _vm.touched.lastname),expression:"!errors.has('lastname') && touched.lastname"}],staticClass:"success"},[_vm._v(" done ")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('usergroup') && _vm.touched.usergroup},
                {'md-error': _vm.errors.has('usergroup')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('users.group')))]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.usergroup),expression:"modelValidations.usergroup"}],attrs:{"name":"usergroup","id":"usergroup","data-vv-name":"usergroup","required":""},model:{value:(_vm.usergroup),callback:function ($$v) {_vm.usergroup=$$v},expression:"usergroup"}},_vm._l((_vm.groups),function(group){return _c('md-option',{key:group.id,attrs:{"value":group.id}},[_vm._v(" "+_vm._s(group.name)+" ")])}),1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},_vm._l((_vm.roles),function(role){return _c('md-radio',{key:role,attrs:{"value":role},model:{value:(_vm.userrole),callback:function ($$v) {_vm.userrole=$$v},expression:"userrole"}},[_vm._v(" "+_vm._s(_vm.$t(("users.role_" + role)))+" ")])}),1)]),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-success",attrs:{"native-type":"submit"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate($event)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]),_c('md-button',{staticClass:"md-accent",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel($event)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" ")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }